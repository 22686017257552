import { IAlgoliaSearchState } from './models';

export const AlgoliaFiltersParams = {
  usualFacet: 'tags.id', // it used for categories for products
  langFacet: 'lang',
};

export const AlgoliaUrlParams = {
  filterSection: 'fs',
};

export const HitsPerPage = 999;

export const DefaultSearchState: IAlgoliaSearchState = {
  configure: {
    analytics: false,
    distinct: true,
    hitsPerPage: HitsPerPage,
  },
  page: 1,
  optionalFilters: '',
  refinementList: {},
};
