/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Configure, InstantSearch } from 'react-instantsearch-dom';
import { graphql } from 'gatsby';
import CustomHits from 'algolia/components/CustomHits';
import FiltersBlock from 'algolia/components/FiltersBlock';
import { AlgoliaFiltersParams, DefaultSearchState, HitsPerPage } from 'algolia/constants';
import {
  getChosenFilterItems,
  getDefaultFiltersParams,
  getSavedSelectionDataToSet,
  parseFilterSections,
  removeItemInRefinementList,
  setFilteredUrlParams,
} from 'algolia/utils/helpers';
import algoliasearch from 'algoliasearch/lite';

import { IPropsAlgoliaFilters } from './models';
import { IAlgoliaSearchState } from 'algolia/models';

import './AlgoliaFilters.scss';

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID as string,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY as string
);

const AlgoliaFilters: FC<IPropsAlgoliaFilters> = ({
  filtersStructure,
  indexName,
  saveAlgoliaHitsResponse,
  handleAlgoliaFiltersUsed,
  handleHitsResponseActivated,
  isHitsResponseActivated,
  lang,
  activeCategoryId,
}): ReactElement | null => {
  const [searchState, setSearchState] = useState<IAlgoliaSearchState>(DefaultSearchState);

  const filtersStructureData = filtersStructure[0];
  const filterSections = useMemo(
    () => parseFilterSections(filtersStructureData.filterSections),
    [filtersStructureData.filterSections]
  );

  const savedSelectionData = getSavedSelectionDataToSet(filterSections);
  useEffect(() => {
    if (!savedSelectionData) {
      setSearchState(DefaultSearchState);
    }
  }, [savedSelectionData]);

  const { chosenFilterItems, chosenFilterIds } = useMemo(
    () => getChosenFilterItems(filterSections, searchState.refinementList),
    [filterSections, searchState.refinementList]
  );

  const handleActiveFiltersIdsOnLoad = useCallback(
    (filterSectionsData: ProductFilters.IFilterSection[]) => {
      const newSearchState = getSavedSelectionDataToSet(filterSectionsData);
      if (!newSearchState) {
        return;
      }
      handleAlgoliaFiltersUsed(true);
      setSearchState(newSearchState);
    },
    []
  );

  useEffect(() => {
    if (!filterSections) {
      return;
    }

    handleActiveFiltersIdsOnLoad(filterSections);
  }, [filterSections]);

  const handleResetSelectionData = useCallback(() => {
    if (chosenFilterItems.length) {
      setSearchState({ ...DefaultSearchState });
    }
    setFilteredUrlParams(filterSections);
  }, [chosenFilterItems.length]);

  const checkAlgoliaFiltersUsage = useCallback((newSearchState: IAlgoliaSearchState) => {
    if (!newSearchState?.refinementList) {
      handleAlgoliaFiltersUsed(false);

      return;
    }

    if (Object.keys(newSearchState.refinementList).length) {
      handleAlgoliaFiltersUsed(true);

      return;
    }

    handleAlgoliaFiltersUsed(false);
  }, []);

  const handleOnSearchStateChange = useCallback((newSearchState: IAlgoliaSearchState) => {
    setFilteredUrlParams(filterSections, newSearchState.refinementList);
    checkAlgoliaFiltersUsage(newSearchState);
    setSearchState(newSearchState);
  }, []);

  const handleRemoveSelectionData = useCallback(
    (sectionId: string, itemId: number) => () => {
      setSearchState((oldState: IAlgoliaSearchState) => {
        const newSearchState = {
          ...oldState,
          refinementList: {
            ...oldState.refinementList,
          },
        };

        const sectionData = removeItemInRefinementList(
          oldState?.refinementList?.[sectionId],
          String(itemId)
        );

        if (!sectionData) {
          delete newSearchState.refinementList[sectionId];
        } else {
          newSearchState.refinementList[sectionId] = sectionData;
        }

        setFilteredUrlParams(filterSections, newSearchState.refinementList);

        return newSearchState;
      });
    },
    []
  );

  return searchClient && filtersStructureData ? (
    <div data-testid="AlgoliaFilters">
      <InstantSearch
        indexName={indexName}
        searchClient={searchClient}
        refresh
        stalledSearchDelay={500}
        searchState={searchState}
        onSearchStateChange={handleOnSearchStateChange}
      >
        <Configure
          filters={getDefaultFiltersParams(AlgoliaFiltersParams.usualFacet, activeCategoryId, lang)}
          hitsPerPage={HitsPerPage}
          analytics={false}
          distinct
          maxValuesPerFacet={HitsPerPage}
        />

        <FiltersBlock
          filterSections={filterSections}
          handleRemoveSelectionData={handleRemoveSelectionData}
          chosenFilterIds={chosenFilterIds}
          title={filtersStructureData.title}
          arrowIconAlt={filtersStructureData.arrowIconAlt}
          arrowIconAriaLabel={filtersStructureData.arrowIconAriaLabel}
          resetBtn={filtersStructureData.resetBtn}
          handleResetSelectionData={handleResetSelectionData}
        />

        <CustomHits
          saveAlgoliaHitsResponse={saveAlgoliaHitsResponse}
          handleHitsResponseActivated={handleHitsResponseActivated}
          isHitsResponseActivated={isHitsResponseActivated}
        />
      </InstantSearch>
    </div>
  ) : null;
};

export const query = graphql`
  fragment FiltersStructureFragment on FiltersStructureType {
    title
    arrowIconAriaLabel
    arrowIconAlt
    resetBtn {
      ...ButtonFragment
    }
    filterSections {
      title
      tagsTaxonomy {
        taxonomy
      }
      colorStructure {
        color
      }
      urlParameter
      filterItemsOrder
      filterItems {
        id
        title
        urlParameter
        tagsTaxonomy {
          taxonomy
        }
        icon {
          ...IconListFragment
        }
      }
    }
  }
`;

export default AlgoliaFilters;
