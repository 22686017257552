import React, { FC, ReactElement } from 'react';
import ConnectedCustomRefinementList from 'algolia/components/CustomRefinementList';
import classNames from 'classnames';

import Button from 'common/Button';

import { IPropsFiltersBlock } from './models';

import './FiltersBlock.scss';

const FiltersBlock: FC<IPropsFiltersBlock> = ({
  filterSections,
  chosenFilterIds,
  handleRemoveSelectionData,
  title,
  arrowIconAlt,
  arrowIconAriaLabel,
  resetBtn,
  handleResetSelectionData,
}): ReactElement | null => (
  <div
    data-testid="FiltersBlock"
    className={classNames('filters-block', {
      'filters-block--multiple-items': filterSections && filterSections?.length > 1,
    })}
  >
    <h2 className="filters-block__title">{title}</h2>

    <Button
      disabled={Boolean(!chosenFilterIds?.length)}
      className="filters-block__reset-btn"
      onClick={handleResetSelectionData}
      {...resetBtn}
    />

    {filterSections?.length ? (
      <div className="filters-block__items">
        {filterSections.map(
          ({ attributeForFaceting, filterItems, title: sectionTitle, colorStructure }) => (
            <ConnectedCustomRefinementList
              key={sectionTitle}
              operator="and"
              attribute={attributeForFaceting}
              filterItems={filterItems}
              sectionId={attributeForFaceting}
              title={sectionTitle}
              chosenFilterIds={chosenFilterIds}
              handleRemoveSelectionData={handleRemoveSelectionData}
              arrowIconAlt={arrowIconAlt}
              arrowIconAriaLabel={arrowIconAriaLabel}
              colorStructure={colorStructure}
            />
          )
        )}
      </div>
    ) : null}
  </div>
);

export default FiltersBlock;
