import React, { FC } from 'react';
import classNames from 'classnames';

import { DangerouslySetInnerHtml } from 'layout';
import Icon from 'common/Icon';

import { IPropsCustomCheckBox } from './models';

import './CustomCheckBox.scss';

const CustomCheckBox: FC<IPropsCustomCheckBox> = ({
  isChecked,
  isDisabled,
  id,
  name,
  labelText,
  onChange,
  icon,
  arrowIconAlt,
  arrowIconAriaLabel,
  color,
}) => (
  <div
    data-testid="CustomCheckBox"
    className={classNames('custom-checkbox', {
      'custom-checkbox--disabled': isDisabled,
      [`custom-checkbox--${color}`]: color,
    })}
  >
    <input
      className="custom-checkbox__input"
      type="checkbox"
      checked={isChecked}
      onChange={onChange}
      id={`custom-checkbox-${id}`}
      name={name}
    />
    <label htmlFor={`custom-checkbox-${id}`}>
      {icon ? (
        <div className="custom-checkbox__icon-wrapper">
          <Icon
            className={classNames('custom-checkbox__icon', {
              [`custom-checkbox__icon-${icon.icon}`]: icon,
            })}
            {...icon}
            alt={labelText}
          />
        </div>
      ) : null}

      <div className="custom-checkbox__label-wrapper">
        <DangerouslySetInnerHtml html={labelText} className="custom-checkbox__label" />
      </div>

      <div className="custom-checkbox__arrow-wrapper">
        <Icon
          className="custom-checkbox__arrow"
          icon="arrow-right-bold"
          ariaLabel={arrowIconAriaLabel}
          alt={arrowIconAlt}
        />
      </div>
    </label>
  </div>
);

export default CustomCheckBox;
