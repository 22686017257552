import { LinkButtonMock } from 'common/Button';

import { CategoriesListProps } from '../models';

export const CategoriesListDefaultClassesMock = ['categories__items'];

export const CategoriesListDefaultPropsMock: CategoriesListProps = {
  productCategories: [LinkButtonMock, LinkButtonMock],
  bannerVariant: 'simple',
  description: 'Nos gels quotidiens pour une routine intime pleine de douceur.',
};
