import { AlgoliaUrlParams } from 'algolia/constants';

import { generateLocationQueryString, generateUrlParamsArray } from './browserHelpers';

import { IAlgoliaFilterIdUrlParam, IChosenFilterItem } from 'algolia/models';

export const encodeFilterIdParam = ({ sectionId, itemId }: IAlgoliaFilterIdUrlParam): string =>
  `${sectionId}-${itemId}`;

export const decodeFilterIdParam = (param: string): IAlgoliaFilterIdUrlParam => {
  const rawData = param.split('-');

  return { sectionId: rawData[0], itemId: rawData[1] };
};

export const getUrlToSet = (
  chosenFilterItems: IChosenFilterItem[],
  pathname?: string
): string | null => {
  const chosenFilterIds = chosenFilterItems.map((item: IChosenFilterItem) =>
    encodeFilterIdParam({ sectionId: item.sectionUrlParameter, itemId: item.itemUrlParameter })
  );

  if (!chosenFilterIds.length) {
    return null;
  }

  return generateLocationQueryString(
    AlgoliaUrlParams.filterSection,
    generateUrlParamsArray(chosenFilterIds),
    pathname
  );
};
