import React, { FC } from 'react';
import classNames from 'classnames';
import FadeIn from 'framers/FadeIn';

import Button from 'common/Button';
import Typography from 'common/Typography';

import { CategoriesListProps } from './models';

import './CategoriesList.scss';

const CategoriesList: FC<CategoriesListProps> = ({
  productCategories,
  description,
  bannerVariant,
}) => {
  const isSimpleBanner = bannerVariant === 'simple';

  const categoriesTitleClasses = classNames('categories__title', {
    'categories__title--hero': isSimpleBanner,
    'categories__title--hero-pro': !productCategories?.length,
  });

  return (
    <FadeIn customClass="categories">
      {productCategories?.length ? (
        <ul className="categories__items" data-testid="categories__items">
          {productCategories.map((button) => (
            <li className="categories__item" key={button.label}>
              <Button
                {...button}
                className="categories__btn"
                activeClassName="categories__btn--active"
                ui={{
                  variant: 'filter',
                  typography: {
                    size: { base: 20, xl: 25 },
                    padding: {
                      top: { base: 'xxxxs', xl: 'xxxs' },
                      right: { base: 'xxxxs', xl: 'xxs' },
                      bottom: { base: 'xxxxs', xl: 'xxs' },
                      left: { base: 'xxxxs', xl: 'xxxs' },
                    },

                    align: 'center',
                    weight: 'bold',
                  },
                  structureAlias: 'buttonUI',
                }}
              >
                {button.label}
              </Button>
            </li>
          ))}
        </ul>
      ) : null}
      {description ? (
        <Typography
          color="deep-koamaru"
          weight="bold"
          padding={{ bottom: 'md' }}
          size={{ base: 26, xl: 40 }}
          lineHeight="small"
          align="center"
          className={categoriesTitleClasses}
          dangerouslySetInnerHTML={description}
        />
      ) : null}
    </FadeIn>
  );
};
export default CategoriesList;
