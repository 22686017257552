import { getLocationPath, isBrowser } from 'utils/browser';

export const getRemovedParamFromLocationQueryString = (param: string): string | null => {
  if (!isBrowser()) {
    return '';
  }
  const qs = new URLSearchParams(window.location.search);
  qs.delete(param);

  const result = qs.toString();
  if (!result) {
    return getLocationPath();
  }

  return `${getLocationPath()}?${qs.toString()}`;
};

export const setUrl = (url: string): void => {
  if (isBrowser()) {
    window.history.replaceState({}, '', url);
  }
};

export const generateLocationQueryString = (param: string, value: string, pathname?: string) => {
  if (!isBrowser()) {
    return '';
  }
  const qs = new URLSearchParams(window.location.search);
  qs.set(param, value);

  return `${pathname || getLocationPath()}?${param}=${value}`;
};

export const generateUrlParamsArray = (arrData: string[]): string => arrData.toString();

export const getLocationQueryStringParam = (param: string): string[] | null => {
  if (!isBrowser()) {
    return [];
  }
  const qs = new URLSearchParams(window.location.search);
  const stringValue = decodeURIComponent(qs.get(param) || '');

  if (!qs.has(param)) {
    return null;
  }

  return stringValue.split(',');
};
