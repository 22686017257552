import React, { FC, ReactElement, useCallback, useEffect, useMemo } from 'react';
import { RefinementListProvided } from 'react-instantsearch-core';
import { connectRefinementList } from 'react-instantsearch-dom';
import CustomCheckBox from 'algolia/components/CustomCheckBox';

import { IPropsCustomRefinementList } from './model';

import { IAlgoliaRefinementItem, IRefinementItem } from 'algolia/models';

import './CustomRefinementList.scss';

export const CustomRefinementList: FC<IPropsCustomRefinementList> = ({
  items,
  refine,
  filterItems,
  sectionId,
  title,
  chosenFilterIds,
  handleRemoveSelectionData,
  arrowIconAlt,
  arrowIconAriaLabel,
  colorStructure,
}): ReactElement | null => {
  const uncheckSelectedDisabledItems = useCallback(
    (itemsToCheck: RefinementListProvided['items']) => {
      itemsToCheck.forEach((item) => {
        if (item.count === 0 && item.isRefined) {
          handleRemoveSelectionData(sectionId, parseInt(item.label, 10))();
        }
      });
    },
    [sectionId, handleRemoveSelectionData]
  );

  useEffect(() => {
    uncheckSelectedDisabledItems(items);
  }, [items, uncheckSelectedDisabledItems]);

  const handleTransformItems = useCallback(
    (
      refinementItems: IAlgoliaRefinementItem[],
      cmsItems: ProductFilters.ProductTagType[]
    ): IRefinementItem[] => {
      const data = cmsItems.map((filterItem: ProductFilters.ProductTagType) => {
        const refinementItem = refinementItems.find((item) => item.label === String(filterItem.id));

        return {
          ...filterItem,
          count: refinementItem?.count || 0,
          isRefined: refinementItem?.isRefined || false,
          label: filterItem.title,
          value: refinementItem?.value || [String(filterItem.id)],
        };
      });

      return data;
    },
    []
  );

  const handleOnChange = useCallback(
    (item: IRefinementItem) => () => {
      refine(item.value);
    },
    [refine]
  );

  const itemsToRender = useMemo(
    () => handleTransformItems(items, filterItems),
    [items, filterItems, handleTransformItems]
  );

  return (
    <div data-testid="CustomRefinementList" className="custom-refinement-list">
      <div className="custom-refinement-list__content">
        <h3 className="custom-refinement-list__title">{title}</h3>
        {itemsToRender.map((item: IRefinementItem) => (
          <CustomCheckBox
            key={item.id}
            isChecked={chosenFilterIds?.includes(String(item.id))}
            labelText={item.title}
            id={`${title}-${item.title}-${item.id}-AlgoliaFilters`}
            name={`${title}-${item.title}-${item.id}-AlgoliaFilters`}
            icon={item.icon[0]}
            isDisabled={item.count === 0}
            onChange={handleOnChange(item)}
            arrowIconAlt={arrowIconAlt}
            arrowIconAriaLabel={arrowIconAriaLabel}
            color={colorStructure[0].color[0]}
          />
        ))}
      </div>
    </div>
  );
};

const ConnectedCustomRefinementList = connectRefinementList(CustomRefinementList);

export default ConnectedCustomRefinementList;
