import { FC, ReactElement, useEffect } from 'react';
import { connectHits } from 'react-instantsearch-dom';

import { IPropsCustomHits } from './model';

export const CustomHits: FC<IPropsCustomHits> = ({
  hits,
  saveAlgoliaHitsResponse,
  handleHitsResponseActivated,
  isHitsResponseActivated,
}): ReactElement | null => {
  useEffect(() => {
    if (!isHitsResponseActivated && hits.length) {
      handleHitsResponseActivated(true);
    }
  }, [isHitsResponseActivated, hits, handleHitsResponseActivated]);

  useEffect(() => {
    saveAlgoliaHitsResponse(hits);
  }, [hits, saveAlgoliaHitsResponse]);

  return null;
};

const ConnectedCustomHits = connectHits(CustomHits);

export default ConnectedCustomHits;
