import React, { FC } from 'react';
import { graphql } from 'gatsby';
import AlgoliaFilters from 'algolia/components/AlgoliaFilters';
import useAlgoliaResponse from 'algolia/hooks/useAlgoliaResponse';

import { Layout } from 'layout';
import Banner from 'components/Banner';
import { CardType } from 'components/Card';
import CategoriesList from 'components/CategoriesList';
import Infographics from 'components/Infographics';
import CardListing from 'containers/CardListing';

import { sortByOrder } from './helpers';

import './ProductListingPage.scss';

const ProductListingPage: FC<ProductListingPageTypes.ProductListingPageProps> = ({
  data: {
    productsListingPage: {
      urls,
      seo,
      banner,
      productCategories,
      description,
      infographic,
      footerBanner,
      lang,
      tags,
      filterType,
    },
    filtersStructure,
    allProductDetailPage: { productsList },
  },
}) => {
  const productsListSorted = productsList.sort(sortByOrder);

  const {
    itemsToRender,
    saveAlgoliaHitsResponse,
    handleAlgoliaFiltersUsed,
    handleHitsResponseActivated,
    isHitsResponseActivated,
  } = useAlgoliaResponse(productsListSorted);

  const filteredItems: CardType[] = itemsToRender
    .map((item) => {
      const dataSource = productsListSorted.find((product) => item.id === product.id)!;

      return {
        ...dataSource,
      };
    })
    .sort(sortByOrder);

  return (
    <Layout {...{ urls, seo }}>
      <div className="product-listing-page">
        {banner ? <Banner {...banner} className="product-listing-page__banner" /> : null}
        <div className="product-listing-page__content">
          <CategoriesList
            {...{
              productCategories,
              description,
              bannerVariant: banner?.ui.variant,
            }}
          />
          {filtersStructure?.nodes?.[0] && filterType?.[0]?.type ? (
            <AlgoliaFilters
              indexName={`${process.env.GATSBY_ALGOLIA_PREFIX}-filters`}
              filtersStructure={filtersStructure.nodes}
              saveAlgoliaHitsResponse={saveAlgoliaHitsResponse}
              handleAlgoliaFiltersUsed={handleAlgoliaFiltersUsed}
              handleHitsResponseActivated={handleHitsResponseActivated}
              isHitsResponseActivated={isHitsResponseActivated}
              lang={lang}
              activeCategoryId={parseInt(tags[0].id, 10)}
            />
          ) : null}
          <CardListing cards={filteredItems} variant="product" />
        </div>
        {infographic?.length ? (
          <div className="product-listing-page__infographic">
            <Infographics infographics={infographic} />
          </div>
        ) : null}
        {footerBanner ? (
          <div className="product-listing-page__footer-banner">
            <Banner {...footerBanner} />
          </div>
        ) : null}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ProductListingPageQuery(
    $lang: String
    $path: [String]
    $tagNames: [String]
    $filterType: String
  ) {
    productsListingPage(lang: { eq: $lang }, url: { in: $path }) {
      seo {
        ...SEOStructureFragment
      }
      urls {
        ...UrlsFragment
      }
      banner {
        ...BannerFragment
      }
      infographic {
        ...InfographicFragment
      }
      footerBanner {
        ...BannerFragment
      }
      productCategories {
        ...ButtonFragment
      }
      lang
      tags {
        id
        tag
      }
      description
      pageName
      filterType {
        type
      }
    }

    filtersStructure: allFiltersStructureType(
      filter: { filterType: { elemMatch: { type: { eq: $filterType } } } }
    ) {
      nodes {
        ...FiltersStructureFragment
      }
    }

    allProductDetailPage(filter: { tags: { elemMatch: { name: { in: $tagNames } } } }) {
      productsList: nodes {
        ...ProductCardFragment
      }
    }
  }
`;

export default ProductListingPage;
