/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useState } from 'react';

import { IHookAlgoliaResponse } from './model';

const useAlgoliaResponse = (defaultItems: any[]): IHookAlgoliaResponse => {
  const [isAlgoliaFiltersUsed, setAlgoliaFiltersUsed] = useState<boolean>(false);
  const [isHitsResponseActivated, setHitsResponseActivated] = useState<boolean>(false);
  const [filteredItems, setFilteredItems] = useState<any[]>([]);

  const itemsToRender =
    isAlgoliaFiltersUsed && isHitsResponseActivated ? filteredItems : defaultItems;

  useEffect(() => {
    setFilteredItems(defaultItems);
  }, []);

  const saveAlgoliaHitsResponse = useCallback((items: any[]) => {
    setFilteredItems(items);
  }, []);

  const handleAlgoliaFiltersUsed = useCallback((value: boolean) => {
    setAlgoliaFiltersUsed(value);
  }, []);

  const handleHitsResponseActivated = useCallback((value: boolean) => {
    setHitsResponseActivated(value);
  }, []);

  return {
    itemsToRender,
    saveAlgoliaHitsResponse,
    handleAlgoliaFiltersUsed,
    handleHitsResponseActivated,
    isHitsResponseActivated,
  };
};

export default useAlgoliaResponse;
