import { AlgoliaUrlParams, DefaultSearchState } from 'algolia/constants';

import {
  getLocationQueryStringParam,
  getRemovedParamFromLocationQueryString,
  setUrl,
} from './browserHelpers';
import { decodeFilterIdParam, getUrlToSet } from './url';

import {
  IAlgoliaFilterIdUrlParam,
  IAlgoliaRefinementList,
  IAlgoliaSearchState,
  IChosenFilterItemsResult,
} from 'algolia/models';

export const removeItemInRefinementList = (
  refinementIds: string[] | undefined,
  refinementId: string
): string[] | null => {
  if (!refinementIds?.length) {
    return null;
  }

  const data = refinementIds.filter((item: string) => item !== refinementId);

  if (!data.length) {
    return null;
  }

  return data;
};

export const isActiveRefinement = (
  refinementList: IAlgoliaRefinementList,
  refinementId: string,
  sectionId: string
): boolean => Boolean(refinementList?.[sectionId]?.includes(refinementId));

export const getSavedSelectionDataToSet = (
  filterSections: ProductFilters.IFilterSection[] | null
): IAlgoliaSearchState | null => {
  const chosenSavedFilterIds = getLocationQueryStringParam(AlgoliaUrlParams.filterSection);

  if (!filterSections?.length || !chosenSavedFilterIds?.length) {
    return null;
  }

  const dataToSet = chosenSavedFilterIds.map((item: string) => decodeFilterIdParam(item));

  const selectionDataToSet = { ...DefaultSearchState, refinementList: {} };
  dataToSet.forEach((item: IAlgoliaFilterIdUrlParam) => {
    const sectionData = filterSections.find((section) => section.urlParameter === item.sectionId);

    if (!sectionData?.attributeForFaceting) {
      return;
    }

    const tagData = sectionData.filterItems.find((tag) => tag.urlParameter === item.itemId);

    if (!tagData?.id) {
      return;
    }

    if (!selectionDataToSet.refinementList[sectionData.attributeForFaceting]) {
      selectionDataToSet.refinementList[sectionData.attributeForFaceting] = [];
    }

    selectionDataToSet.refinementList[sectionData.attributeForFaceting].push(String(tagData.id));
  });

  return selectionDataToSet;
};

export const getChosenFilterItems = (
  sections: ProductFilters.IFilterSection[] | null,
  refinementList?: IAlgoliaRefinementList
): IChosenFilterItemsResult => {
  const data: IChosenFilterItemsResult = {
    chosenFilterItems: [],
    chosenFilterIds: [],
    chosenSectionIds: [],
  };

  if (!refinementList || !sections) {
    return data;
  }

  sections.forEach((section: ProductFilters.IFilterSection) => {
    section.filterItems.forEach((item: ProductFilters.ProductTagType) => {
      if (!isActiveRefinement(refinementList, String(item.id), section.attributeForFaceting)) {
        return;
      }

      data.chosenFilterItems.push({
        title: item.title,
        itemId: item.id,
        itemUrlParameter: item.urlParameter,
        sectionId: section.attributeForFaceting,
        sectionUrlParameter: section.urlParameter,
      });
      data.chosenFilterIds.push(String(item.id));

      if (!data.chosenSectionIds.includes(section.attributeForFaceting)) {
        data.chosenSectionIds.push(section.attributeForFaceting);
      }
    });
  });

  return data;
};

export const getDefaultFiltersParams = (
  categoryFacetName: string,
  activeCategoryId: number | null,
  lang: string,
  additionalFilterParams?: string
): string =>
  `${activeCategoryId ? `${categoryFacetName}:${activeCategoryId} AND ` : ''}lang:${lang}${
    additionalFilterParams || ''
  }`;

export const setFilteredUrlParams = (
  sections: ProductFilters.IFilterSection[] | null,
  refinementList?: IAlgoliaRefinementList
): void => {
  const { chosenFilterItems } = getChosenFilterItems(sections, refinementList);

  let url = getUrlToSet(chosenFilterItems);

  if (!url) {
    url = getRemovedParamFromLocationQueryString(AlgoliaUrlParams.filterSection);
  }

  setUrl(url || '');
};

export const sortFilterItems = (
  filterItems: ProductFilters.IFilterSection['filterItems'],
  filterItemsOrder?: string
): ProductFilters.IFilterSection['filterItems'] => {
  if (!filterItemsOrder) {
    return filterItems;
  }

  const result: ProductFilters.IFilterSection['filterItems'] = [];

  filterItemsOrder.split(',').forEach((orderItem) => {
    const data = filterItems.find((item) => item.id === parseInt(orderItem, 10));
    if (data) {
      result.push(data);
    }
  });

  return result;
};

export const parseFilterSections = (
  param: [ProductFilters.IFilterSection]
): ProductFilters.IFilterSection[] | null =>
  param?.length
    ? param.map((item: ProductFilters.IFilterSection) => ({
        title: item.title,
        attributeForFaceting: item?.tagsTaxonomy?.[0]?.taxonomy
          ? `${item.tagsTaxonomy[0].taxonomy}.id`
          : '',
        filterItems: sortFilterItems(item.filterItems, item?.filterItemsOrder),
        tagsTaxonomy: item.tagsTaxonomy,
        colorStructure: item.colorStructure,
        urlParameter: item.urlParameter,
      }))
    : null;
